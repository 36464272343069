/* 基础滚动条隐藏 */
div::-webkit-scrollbar {
  display: none;
}

/* 聊天窗口标题栏 */
.chat-header {
  height: 48px;
  display: flex;
  align-items: center;
  background-color: #8B5CF6;
  padding: 0 8px;
  position: relative;
}

/* 标签容器 */
.chat-tabs-container {
  display: flex;
  width: 100%;
  height: 48px;
  align-items: flex-end;
  padding-top: 8px;
}

/* 标签按钮 */
.chat-tab-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33.333%;
  height: 40px;
  padding: 0 16px;
  border-radius: 8px 8px 0 0;
  transition: all 0.2s;
  margin-bottom: 0;
  font-weight: 600;
}

.chat-tab-button.active {
  background-color: rgba(244, 241, 207, 0.753);
  color: #1F2937;
}

.chat-tab-button:not(.active) {
  background-color: #8B5CF6;
  color: white;
}

.chat-tab-button:hover:not(.active) {
  background-color: rgba(139, 92, 246, 0.9);
}

/* 头像样式 */
.chat-avatar {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 8px;
  flex-shrink: 0;
}

.chat-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* 固定位置的新对话按钮
.fixed-new-chat-button {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 16px;
    z-index: 1000;
    background-color: white;
    padding: 4px 12px;
    border-radius: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    transition: all 0.2s ease;
  }
  
  .fixed-new-chat-button:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    transform: translateX(-50%) translateY(-2px);
  }
  
  .fixed-new-chat-button button {
    white-space: nowrap;
    display: flex;
    align-items: center;
    gap: 8px;
    color: #8B5CF6;     /* 添加紫色 */
    /* font-weight: 500;   /* 稍微加粗 */

/* 新对话按钮 */
.fixed-new-chat-button {
    position: absolute;
    top: 8px;
    right: 8px;
    background-color: #8B5CF6;
    color: white;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    padding: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.2s;
    z-index: 10;
    display: flex;
    align-items: center;
    
    justify-content: center;
    animation: pulse-attention 2s infinite;
  }
  
  .fixed-new-chat-button:hover {
    background-color: #7C3AED;
    transform: scale(1.05);
    animation: none; /* 悬停时停止动画 */
  }


  /* 脉冲动画 */
@keyframes pulse-attention {
    0% {
      box-shadow: 0 0 0 0 rgba(139, 92, 246, 0.7);
      transform: scale(1);
    }
    
    70% {
      box-shadow: 0 0 0 10px rgba(139, 92, 246, 0);
      transform: scale(1.05);
    }
    
    100% {
      box-shadow: 0 0 0 0 rgba(139, 92, 246, 0);
      transform: scale(1);
    }
  }


/* 长会话提示动画 - 当对话时间超过一定长度时激活 */
.fixed-new-chat-button.urgent {
    animation: shake-attention 1.5s ease infinite;
    background-color: #ef4444; /* 红色背景以示紧急 */
  }
  
  /* 摇晃动画 - 用于提示用户考虑开始新对话 */
  @keyframes shake-attention {
    0%, 100% { transform: translateX(0); }
    10%, 30%, 50%, 70%, 90% { transform: translateX(-2px) rotate(-2deg); }
    20%, 40%, 60%, 80% { transform: translateX(2px) rotate(2deg); }
  }

  /* 移除旧的固定位置新对话按钮 */
/* .fixed-new-chat-button {
    display: none;
  } */
/* 消息容器样式 */
.message-container.ai {
  background-color: #f5f5f5;
}

/* 用户图片容器 */
.user-images {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 8px;
}

/* 聊天图片样式 */
.chat-image {
  position: relative;
  border-radius: 4px;
  overflow: hidden;
}